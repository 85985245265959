import { Component, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  app: string = 'SHOPClass';

  constructor() {
    setTheme('bs4');
  }
  
  ngOnInit() {
    const url = window.location.hostname;
    this.app = url.split('.')[0];
  }
}
